import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import { ADDRESS_SOURCES } from '@/domains/constants';
import {
  TQuickActions,
  TFilterSet,
  TFolder,
  TPaginationForRequest,
  TPricesResponse,
  TFileUploadInstructions,
  TNewFolderResponse,
  TFolderInfoResponse,
  TFileUploadInfo,
  TCoords,
  TPagination,
} from '@/types';
import {
  TFreightRequestsResponse,
  TAutocompleteAddressRequest,
  TAutocompleteAddressResponse,
  TAutocompleteExecutors,
  TFreightRequestResponse,
  TAutocompleteAcceptedExecutorsResponse,
  TAvailableAutomationsResponse,
  TOrdersResponse,
  TOrderLookupsResponse,
  TExecutionRequestsResponse,
  TCreateFreightRequestWithOrderPayload,
  TCreateFreightRequestWithOrderResponse,
  TOrderResponse,
  TActiveExecutionRequestsResponse,
  TOrderInList,
  TOrderQuantitiesResponse,
  TActiveOrderLookupsResponse,
  TOrdersTableGetFilterIdResponse,
  TCreationShipmentRequest,
  TShipmentsResponse,
  TShipmentResponse,
  TShipmentBookingsResponse,
  TOrderDirectionResponse,
  TOrderDirectionsResponse,
  TFieldAutocompleteTemplatesResponse,
  TOrdersTableFilterItem,
  TContractorProgressResponse,
  TExecutorsLookupUnvisitedResponse,
  TExecutorsLookupVisitsResponse,
  TExecutorsLookupBidsResponse,
  TExecutorsLookupOffersResponse,
  TOrderIncidentKind,
  TFolderCustomFiltersPayload,
  TAuditEventsResponse,
  TOrdersResponseParams,
  TShipmentsPayload,
  TModalityCombinationsResponse,
  TRunsByOrderParams,
  TRunsByOrderResponse,
  TShipmentBookedTimeSlot,
  TShipmentAvailableTimeSlotsResponse,
  TAgreementByOrderResponse,
  TPreselectColumnsResponse,
  TPreselectMatrixRowsResponse,
} from '@/contexts/orderingContext/domain/types';
import { TOrdersRunsWorkflowStatesResponse }
  from '@/contexts/orderingContext/views/OrdersListView/components/OrdersListOnlyOrders/types';
import { ECurrency, getObjectWithoutEmptyFields } from '@/utils';
import { EEntityType } from '@/contexts/referencesContext/domain/types';
import tt from '@/i18n/utils/translateText';
import {
  TFreeOfferingSettingsRequest,
  TReductionSettingsRequest,
  TSpotOfferSettingsRequest,
  TTargetOfferSettingsRequest,
} from '@/contexts/executorsLookupContext/domain/types';
import {
  TExtraServicesActualPrice,
  TExtraServicesPayload,
  TOrderExtraService,
} from '@/contexts/extraServicesContext/domains/types';
import { TPointToAdd }
  from '@/contexts/orderingContext/components/OrderRoutePointsDrawer/components/PlannedRouteForm/domain/types';
import { TFuncApplySetServiceParams } from '@/components/Filter/types';
import { EAccountingUnit } from '@/contexts/orderingContext/domain/constants';
import { TShipmentBookingPayload } from '@/contexts/orderingContext/views/ShipmentsInfoView/domain/types';

import { ABORT_SIGNAL_NAME } from './constants';
import { TRunTemplate } from '../views/OrdersListView/domain/types';

export function autocompleteAddress({
  query,
  contractorId,
  sources = [ADDRESS_SOURCES.db, ADDRESS_SOURCES.daData],
  fromBound = null,
  toBound = null,
  searchWithin = null,
  addressRequirementsType = null,
}: TAutocompleteAddressRequest) {
  return sendApiRequest<TAutocompleteAddressResponse>({
    method: httpMethods.GET,
    // TODO: references context - справочники ??? или в глобальный контекст ??
    endpoint: '/:tenant_id/autocomplete/addresses',
    params: getObjectWithoutEmptyFields({
      query,
      contractorId,
      sources,
      fromBound,
      toBound,
      searchWithin,
      addressRequirementsType,
    }),
    signalName: ABORT_SIGNAL_NAME.orderingContextAutocompleteAddress,
  });
}

export function fetchFreightRequests({ pageItems, currentPage } = {
  pageItems: null,
  currentPage: null,
} as TPaginationForRequest, complex = false, filter = '') {
  return sendApiRequest<TFreightRequestsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/freight_requests',
    params: {
      items: pageItems,
      page: currentPage,
      filter,
      complex,
    },
  });
}

export function fetchFreightRequestOrders(iid: string) {
  return sendApiRequest<TOrdersResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/freight_requests/${iid}/orders`,
  });
}

export function fetchOrders({
  pageItems,
  currentPage,
  departmentId,
  filterId,
  orderIncidents,
  sort,
  sortBy,
  folderSlug,
  quickFilterId,
} = {
  pageItems: null,
  currentPage: null,
} as TPaginationForRequest & TOrdersResponseParams) {
  return sendApiRequest<TOrdersResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/orders',
    params: {
      items: pageItems,
      page: currentPage,
      departmentId,
      filterId,
      folderSlug,
      orderIncidents,
      quickFilterId,
      sort,
      sortBy,
    },
    signalName: ABORT_SIGNAL_NAME.orderingContextFetchOrders,
  });
}

export const fetchOrderQuantities = (orderIid: number) => sendApiRequest<TOrderQuantitiesResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderIid}/quantities`,
});

export function fetchOrderDirections(orderIid: number) {
  return sendApiRequest<TOrderDirectionsResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/directions`,
  });
}

// Заново отранжировать направления для заказа
export const deleteOrderDirections = (orderIid: number) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/orders/${orderIid}/directions`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export function fetchOrderDirection(orderIid: number, directionId: string) {
  return sendApiRequest<TOrderDirectionResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/directions/${directionId}`,
  });
}

export const fetchActiveOrderLookups = (orderIid: number) => sendApiRequest<TActiveOrderLookupsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderIid}/executors_lookups/active`,
});

export function fetchOrderLookups(orderIid: number) {
  return sendApiRequest<TOrderLookupsResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/executors_lookups`,
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.fetchOrderLookups'),
    },
  });
}

export function fetchAgreementLookups(executionRequestUid: string, executionRequestAgreementId: string) {
  return sendApiRequest<TOrderLookupsResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/execution_requests/${executionRequestUid}/agreements/${executionRequestAgreementId}/executors_lookups`,
  });
}

export const fetchExecutorsLookupBids = (executorsLookupId: number) => sendApiRequest<TExecutorsLookupBidsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/reductions/${executorsLookupId}/bids/recent`,
  requestOptions: {
    showError: true,
    message: tt('order.shared.errors.fetchOrderLookupsBids'),
  },
});

export const fetchExecutorsLookupOffers = (executorsLookupId: number) => sendApiRequest<TExecutorsLookupOffersResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/free_offerings/${executorsLookupId}/offers/recent`,
  requestOptions: {
    showError: true,
    message: tt('order.shared.errors.fetchOrderLookupsBids'),
  },
});

export const fetchExecutorsLookupVisits = (executorsLookupId: number) => sendApiRequest<TExecutorsLookupVisitsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/visits`,
});

export const fetchExecutorsLookupUnvisited = (executorsLookupId: number) => sendApiRequest<TExecutorsLookupUnvisitedResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/unvisited`,
  requestOptions: {
    showError: true,
    message: tt('order.shared.errors.fetchOrderLookupsUnvisited'),
  },
});

export const registerParticipantVisit = (executorsLookupId: number) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/visits`,
});

export function fetchActiveExecutionRequests(orderIid: number) {
  return sendApiRequest<TActiveExecutionRequestsResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/execution_requests/active`,
  });
}

export function fetchExecutionRequests(orderIid: number) {
  return sendApiRequest<TExecutionRequestsResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/execution_requests`,
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.fetchExecutionRequests'),
    },
  });
}

export function changeExecutorsLookupsExpiration(orderIids: number[], expiresAt: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_executors_lookup_expiration_changing',
    payload: {
      orderIids,
      expiresAt,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function cancelOrders(orderIids: number[]) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_cancellation',
    payload: { orderIids },
  });
}

export function resetOrders(orderIids: number[]) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_reset',
    payload: { orderIids },
  });
}

export function resumeExecutorsLookups(orderIids: number[]) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_executors_lookup_resumption',
    payload: { orderIids },
  });
}

export function applyAutomationOnOrders(orderIids: number[], automationId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_automation_launch',
    payload: {
      orderIids,
      automationId,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function createSpotOfferMulti(orderIids: number[], callParams: TSpotOfferSettingsRequest) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_spot_offer_creation',
    payload: {
      orderIids,
      callParams,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function createReductionMulti(orderIids: number[], callParams: TReductionSettingsRequest) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_reduction_creation',
    payload: {
      orderIids,
      callParams,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function createTargetOfferMulti(orderIids: number[], callParams: TTargetOfferSettingsRequest) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_target_offer_creation',
    payload: {
      orderIids,
      callParams,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function createFreeOfferingMulti(orderIids: number[], callParams: TFreeOfferingSettingsRequest) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_free_offer_creation',
    payload: {
      orderIids,
      callParams,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function acceptWinnerExecutorsLookup(orderIids: number[]) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/mass_executors_lookup_winner_acceptance',
    payload: { orderIids },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function startAutomationForOrder(orderIid: number, automationId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/orders/${orderIid}/automations`,
    payload: { automationId },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export const fetchOrderInfoDrawerOrderIncidents = (orderIid: number, resolved = false) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderIid}/order_incidents`,
  params: { resolved },
});

export const getOrderIncidentsKinds = () => sendApiRequest<TOrderIncidentKind[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/order_incident_kinds',
});

export function readOrderInfoDrawerOrderIncident(orderIid: string | number, orderIncidentId: string | number) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/orders/${orderIid}/order_incidents/${orderIncidentId}/read`,
  });
}

export function createFreightRequestWithOrder(payload: TCreateFreightRequestWithOrderPayload) {
  return sendApiRequest<TCreateFreightRequestWithOrderResponse>({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/freight_requests',
    payload: getObjectWithoutEmptyFields(payload),
    requestOptions: { showError: true },
  });
}

export const fetchOrder = (orderIid: number) => sendApiRequest<TOrderResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderIid}`,
});

export const fetchFreightRequestByOrderIid = (orderIid: number) => sendApiRequest<TFreightRequestResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderIid}/freight_request`,
});

export const cancelOrder = (orderIid: number) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderIid}/cancellation`,
});

export const restoreOrder = (orderIid: number) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderIid}/restoration`,
});

export const resetOrder = (orderIid: number) => sendApiRequest<{ iid: number }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderIid}/reset`,
});

export const completeOrder = (orderIid: number) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderIid}/completion`,
});

export function fillPlannedRoute(orderIid: number, payload: { transitPoints: TPointToAdd[], distance?: number }) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/orders/${orderIid}/planned_route`,
    payload,
  });
}

export function editPlannedRoute(orderIid: number, payload: Record<string, any>) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `/:tenant_id/orders/${orderIid}/planned_route`,
    payload,
  });
}

export function changeFreightRequestResponsibleEmployee(freightRequestIid: number, newValue: string) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `/:tenant_id/freight_requests/${freightRequestIid}/responsible_employee.js`,
    payload: { responsibleEmployeeId: newValue },
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.changeFreightRequest'),
    },
  });
}

export function changeFreightRequestCustomer(freightRequestIid: number, newValue: string) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `/:tenant_id/freight_requests/${freightRequestIid}/customer`,
    payload: { customerId: newValue },
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.changeFreightRequest'),
    },
  });
}

export function changeFreightRequestPayer(freightRequestIid: number, newValue: string) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `/:tenant_id/freight_requests/${freightRequestIid}/payer`,
    payload: { payerId: newValue },
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.changeFreightRequest'),
    },
  });
}

export function changeFreightRequestReferenceNumber(freightRequestIid: number, newValue: string) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `/:tenant_id/freight_requests/${freightRequestIid}/reference_number`,
    payload: { referenceNumber: newValue },
  });
}

export function getAcceptedExecutors(query = '') {
  return sendApiRequest<TAutocompleteAcceptedExecutorsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/autocomplete/accepted_executors',
    params: { query },
  });
}

export function fetchVehicleRequirements(query: string[]) {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/additional_vehicle_requirements_schema',
    params: { vehicle_type_ids: query },
  });
}

export function fetchAvailableAutomations(orderIid: number) {
  return sendApiRequest<TAvailableAutomationsResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/automations`,
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.fetchAvailableAutomations'),
    },
  });
}

export function fetchAvailableAutomationsByUnit(unit: EAccountingUnit) {
  return sendApiRequest<TAvailableAutomationsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/orders_automations',
    params: { unit },
  });
}

export function updateRunTtn(executionRequestUid: string, runUid: string, materialLoadListNumber: any) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/execution_requests/${executionRequestUid}/runs/${runUid}/material_load_list_number`,
    payload: { materialLoadListNumber },
  });
}

export function getOrderInfoForOrdersTable(orderIid: number) {
  return sendApiRequest<TOrderInList>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/for_table`,
  });
}

export function saveFilterForOrdersTable({ filters, operator }: TFuncApplySetServiceParams) {
  return sendApiRequest<TOrdersTableGetFilterIdResponse>({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/orders/filters',
    payload: {
      filters,
      operator,
    },
  });
}

export function saveFilterForShipmentsTable({ filters, operator }: TFuncApplySetServiceParams) {
  return sendApiRequest<TOrdersTableGetFilterIdResponse>({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/shipments/filters',
    payload: {
      filters,
      operator,
    },
    requestOptions: { showError: true },
  });
}

export function executorsAutocomplete(query: string) {
  return sendApiRequest<TAutocompleteExecutors>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/autocomplete/as_client/partners',
    params: { query },
  });
}

export function getShipmentsAvailableTimeSlots(shipmentBookingToken: string, exactDate: string) {
  return sendApiRequest<TShipmentAvailableTimeSlotsResponse>({
    method: httpMethods.GET,
    endpoint: `/shipments/${shipmentBookingToken}/available_time_slots`,
    params: { exactDate },
    withoutAuth: true,
  });
}

export function getShipment(shipmentBookingToken: string) {
  return sendApiRequest<TShipmentResponse>({
    method: httpMethods.GET,
    endpoint: `/shipments/${shipmentBookingToken}`,
    withoutAuth: true,
  });
}

export function getShipmentBookings(shipmentBookingToken: string) {
  return sendApiRequest<TShipmentBookingsResponse>({
    method: httpMethods.GET,
    endpoint: `/shipments/${shipmentBookingToken}/bookings`,
    withoutAuth: true,
  });
}

export function bookShipmentTimeSlot(shipmentBookingToken: string, payload: TShipmentBookedTimeSlot) {
  return sendApiRequest<TShipmentBookingsResponse>({
    method: httpMethods.POST,
    endpoint: `/shipments/${shipmentBookingToken}/bookings`,
    withoutAuth: true,
    payload,
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.bookShipmentTimeSlot'),
    },
  });
}

export function rebookShipmentTimeSlot(shipmentBookingToken: string, bookingId: string, payload: TShipmentBookedTimeSlot) {
  return sendApiRequest<TShipmentBookingsResponse>({
    method: httpMethods.PUT,
    endpoint: `/shipments/${shipmentBookingToken}/bookings/${bookingId}/time_slot`,
    withoutAuth: true,
    payload,
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.bookShipmentTimeSlot'),
    },
  });
}

export const getShipmentDocumentsUploadingUrl = ({
  shipmentBookingToken,
  bookingId,
  filename,
  type,
}: {
  shipmentBookingToken: string,
  bookingId: string,
  filename: string,
  type: string,
}) => sendApiRequest<TFileUploadInstructions>({
  method: httpMethods.GET,
  endpoint: `/shipments/${shipmentBookingToken}/bookings/${bookingId}/presign`,
  withoutAuth: true,
  params: {
    filename,
    type,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export function annulShipmentBooking(shipmentId: string, bookingId: string) {
  return sendApiRequest<TShipmentBookingsResponse>({
    method: httpMethods.POST,
    endpoint: `/shipments/${shipmentId}/bookings/${bookingId}/annulation`,
    withoutAuth: true,
    requestOptions: { showError: true },
  });
}

export function updateShipmentBooking(
  shipmentBookingToken: string,
  id: string,
  payload: TShipmentBookingPayload) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `shipments/${shipmentBookingToken}/bookings/${id}`,
    withoutAuth: true,
    payload,
  });
}

export function updateShipmentBookingDriver(
  shipmentBookingToken: string,
  id: string,
  payload: TShipmentBookingPayload) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `shipments/${shipmentBookingToken}/bookings/${id}/driver`,
    withoutAuth: true,
    payload,
  });
}

export function createShipment(payload: TCreationShipmentRequest) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/shipments',
    payload,
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.createShipment'),
    },
  });
}

export function updateShipment(payload: TCreationShipmentRequest, shipmentId: string) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/shipments/${shipmentId}`,
    payload,
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.updateShipment'),
    },
  });
}

export function editOrderInfo(orderIid: string, payload) {
  return sendApiRequest<TOrdersTableGetFilterIdResponse>({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/orders/${orderIid}`,
    payload,
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.editOrderInfo'),
    },
  });
}

export function getShipments({ pageItems, currentPage, filterId }: TShipmentsPayload =
{
  pageItems: null,
  currentPage: null,
  filterId: null,
}) {
  return sendApiRequest<TShipmentsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/shipments',
    params: {
      items: pageItems,
      page: currentPage,
      filterId,
    },
    requestOptions: {
      showError: true,
      message: tt('order.shared.errors.getShipments'),
    },
  });
}

export const getOrderFolders = () => sendApiRequest<TFolder[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/orders/folders',
});

export const getFolders = () => sendApiRequest<TNewFolderResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/folders',
  requestOptions: { showError: true },
});

export const getFolderInfo = (slug: string) => sendApiRequest<TFolderInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/folders/${slug}`,
});

export const getOrdersFolderCustomFilters = (folderId: string) => sendApiRequest<TFilterSet[]>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/folders/${folderId}`,
});

export const setOrderFolderCustomFilters = (
  name: string,
  folderId: string,
  filterSet: TOrdersTableFilterItem[],
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/orders/saved_filters',
  payload: {
    name,
    folderId,
    filterSet,
  },
});

export const setFolderCustomFilters = ({
  name,
  folderId,
  filterSet,
}: TFolderCustomFiltersPayload) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/folders/${folderId}/saved_filters`,
  payload: {
    name,
    folderId,
    filterSet,
  },
});

export function getOrdersQuickActions(folderId: string) {
  return sendApiRequest<TQuickActions>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/folders/${folderId}/quick_actions`,
  });
}

export const getCodes = () => sendApiRequest<string[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/transit_point_codes',
});

export const getRouteDistance = (coords: TCoords[]) => sendApiRequest<{ distance: number }>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/route_distance',
  payload: { coords },
});

export const getFieldAutocompleteTemplates = (entityType: EEntityType[]) => sendApiRequest<TFieldAutocompleteTemplatesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/field_autocomplete_templates',
  params: { entityType },
});

export function getContractorProgress(orderIid: string | number) {
  return sendApiRequest<TContractorProgressResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/contractors_progress`,
    requestOptions: { showError: true },
  });
}

export function setContractorProgress(orderIid: string | number, payload: any) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/orders/${orderIid}/contractors_progress/set`,
    payload,
    requestOptions: { showError: true },
  });
}

export function rollbackContractorProgress(orderIid: string | number, checkmarkId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/orders/${orderIid}/contractors_progress/rollback`,
    payload: { checkmarkId },
    requestOptions: { showError: true },
  });
}

export const importOrders = (file: TFileUploadInfo) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/orders_imports',
  payload: { file },
});

export const getOrderImportInfo = (id: string) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders_imports/${id}`,
});

export const importRuns = (file: TFileUploadInfo) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/runs_imports',
  payload: { file },
});

export const getRunImportInfo = (id: string) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/runs_imports/${id}`,
});

export const getRunsImportTemplates = ({ modality, orderType, fileName }: TRunTemplate) => sendApiRequest<Blob>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/runs_import_templates',
  responseType: 'blob',
  headers: { Accept: 'blob' },
  payload: {
    modality,
    type: orderType,
    fileName,
  },
});

export const addExtraServicesToOrder = (
  orderIid: number,
  payload: {
    extraServices: TExtraServicesPayload[],
    transitPointId?: string,
  }) => sendApiRequest<TOrderExtraService[]>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderIid}/extra_services`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const deleteExtraServicesFromOrder = (orderIid: number, extraServiceId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/orders/${orderIid}/extra_services/${extraServiceId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getActualOrderExtraServices = (orderIid: number) => sendApiRequest<TOrderExtraService[]>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderIid}/extra_services/actual`,
});

export const getExtraServicesPrices = (
  orderIid: number,
  currency: ECurrency,
  executorId: string,
) => sendApiRequest<TExtraServicesActualPrice[]>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderIid}/extra_services/prices`,
  params: {
    currency,
    executorId,
  },
});

export const createReductionFromOrder = (orderIid: number, payload: Record<string, any>) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderIid}/reduction_lookups/`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const createSpotOfferFromOrder = (orderIid: number, payload: Record<string, any>) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderIid}/spot_offer_lookups/`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const createTargetOfferFromOrder = (orderIid: number, payload: Record<string, any>) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderIid}/target_offer_lookups/`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const createFreeOfferingFromOrder = (orderIid: number, payload: Record<string, any>) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderIid}/free_offering_lookups/`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export function fetchReductionPriceFromPriceListsByOrderIid(orderIid: number, currency: ECurrency) {
  return sendApiRequest<TPricesResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/reduction_prices`,
    params: { currency },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function fetchSpotOfferPriceFromPriceListsByOrderIid(orderIid: number, currency: ECurrency) {
  return sendApiRequest<TPricesResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/spot_offer_prices`,
    params: { currency },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function fetchTargetOfferPriceFromPriceListsByOrderIid(
  orderIid: number,
  partnershipId: string,
  currency: ECurrency,
) {
  return sendApiRequest<TPricesResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/target_offer_prices`,
    params: {
      partnershipId,
      currency,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function getRunsWorkflowStates() {
  return sendApiRequest<TOrdersRunsWorkflowStatesResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/orders/runs/workflow_states',
  });
}

export const getShipmentsWorkflowStates = () => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/shipments/bookings/workflow_states',
});

export function getAuditEvents({ orderIid }: { orderIid: number }) {
  return sendApiRequest<TAuditEventsResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/orders/${orderIid}/audit_events`,
  });
}

/** Список возможных модальностей и типов перевозки */
export function getModalityCombinations() {
  return sendApiRequest<TModalityCombinationsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/modality_combinations',
  });
}

/** Получить список рейсов по заказу */
export const getOrderRuns = (
  {
    orderIid,
    pagination,
    filterId,
    quickFilter,
    searchQuery,
  }: TRunsByOrderParams,
) => sendApiRequest<TRunsByOrderResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderIid}/runs`,
  params: {
    items: pagination.pageItems,
    page: pagination.currentPage,
    filterId,
    ...quickFilter,
    searchQuery,
  },
});

/** Получить список соглашений по заказу 1:М */
export const getOrderAgreements = (orderIid: number) => sendApiRequest<TAgreementByOrderResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderIid}/agreements`,
});

export const saveFiltersForRuns = (
  { filters, operator }: TFuncApplySetServiceParams,
  orderId: string,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderId}/runs/filters`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
  payload: {
    filters,
    operator,
  },
});

type TRunStatusOptions = {
  slug: string,
  title: string,
};

export const fetchRunsStatusOptions = () => sendApiRequest<TRunStatusOptions[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/orders/runs/workflow_states',
});

export const updateOrderQuantity = (orderId: number, orderQuantity: number) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/orders/${orderId}`,
  payload: { quantity: orderQuantity },
});

export const refreshMatrixRows = (orderId: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderId}/matrix_rows/refresh`,
});

export const getExecutorsMatrixColumns = (orderId: string) => sendApiRequest<TPreselectColumnsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderId}/matrix_rows/columns`,
});

export const getExecutorsMatrixRows = ({
  orderId,
  excludeRejected,
  pagination,
}: {
  orderId: string,
  excludeRejected: boolean,
  pagination: TPagination,
}) => sendApiRequest<TPreselectMatrixRowsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/orders/${orderId}/matrix_rows`,
  params: {
    items: pagination.pageItems,
    page: pagination.currentPage,
    excludeRejected,
  },
});

export const matrixDistribution = ({
  orderId,
  matrixRowId,
}: {
  orderId: string,
  matrixRowId: string,
}) => sendApiRequest<TPreselectMatrixRowsResponse>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/orders/${orderId}/matrix_distribution`,
  payload: { matrixRowId },
});
