export const ROUTE_NAME = {
  selfBilling: 'selfBilling',
  selfBillingRegistry: 'selfBillingRegistry',
};

export enum ESelfBillingStatus {
  draft = 'draft',
  verification = 'verification',
  accepted = 'accepted',
  canceled = 'canceled',
}

export enum ECreateRegistryCustomResponseCode {
  runsAlreadyAddedInOtherRegistry = 'DomainErrors::SelfBillingContext::RunsAlreadyAddedInOtherRegistry',
  runsNotFinished = 'DomainErrors::SelfBillingContext::RunsNotFinished',
  runsWithDifferentCurrencies = 'DomainErrors::SelfBillingContext::RunsWithDifferentCurrencies',
}
