export enum ESubscriptionFeature {
  makeFreightRequests = 'makeFreightRequests',
  executeRuns = 'executeRuns',
  geomonitoring = 'geomonitoring',
  organizeLookups = 'organizeLookups',
  participateInLookups = 'participateInLookups',
  useDigitalQueues = 'useDigitalQueues',
  useAggregator = 'useAggregator',
  usePriceAnalytics = 'usePriceAnalytics',
  useReportTemplates = 'useReportTemplates',
  useExtraServices = 'useExtraServices',
  useOperationalDocuments = 'useOperationalDocuments',
  trackRuns = 'trackRuns',
}

export enum EActiveSubscriptionsConditionsFeature {
  /** Кабинет ГВ (грузовладельца) */
  cargoOwnerWorkplace = 'cargoOwnerWorkplace',
  /** Кабинет ГО/ГП (грузоотправитель/грузополучатель) */
  shipperWorkplace = 'shipperWorkplace',
  /** Кабинет ГП (грузоперевозчика) */
  carrierWorkplace = 'carrierWorkplace',
  /** Тендерный (стартовый) Кабинет ГП (грузоперевозчика) */
  carrierStarterWorkplace = 'carrierStarterWorkplace',
  /** Дополнительные фичи экспедитора */
  forwarderFeatures = 'forwarderFeatures',
  /** Калькулятор перевозки */
  priceCalculator = 'priceCalculator',
  /** Ограничение автозаполнения ТС */
  autocompleteTransportResources = 'autocompleteTransportResources',
  /** Электронный документооборот */
  operationalDocuments = 'operationalDocuments',
  /** Геомониторинг */
  geomonitoring = 'geomonitoring',
  /** Настройки интеграции */
  integrationSettings = 'integrationSettings',
}

export enum EExternalAccountProviders {
  sber_business = 'sber_business',
}

export enum ExpectedArrivalDateTimeType {
  interval = 'interval',
  determined = 'determined',
  none = 'none',
}

export enum ERegionType {
  область = 'область',
  край = 'край',
  республика = 'республика',
  город = 'город',
  'автономный округ' = 'автономный округ',
  'автономная область' = 'автономная область',
}

export enum ETenantSubscriptionStatus {
  active = 'active',
  finished = 'finished',
}

export enum EmployeeViewSettingsField {
  ordersColumns = 'ordersColumns',
  executionRequestsExecutorColumns = 'executionRequestsExecutorColumns',
}

export enum EExperimentalFeatures {
  /** Доступ к разделу Согласования */
  approvals = 'approvals',
  /** Доступ разделу Документооборот */
  operationalDocuments = 'operationalDocuments',
  /** Возможность выгрузки заказов в Excel */
  ordersExport = 'ordersExport',
  /** Возможность загрузки заказов в Excel */
  ordersImport = 'ordersImport',
  /** Необходимость принятия публичной оферты при регистрации новых тенантов */
  publicOfferAccept = 'publicOfferAccept',
  /** Кастомизация справочника инцидентов */
  runIncidentsCustomization = 'runIncidentsCustomization',
  /** Отображение кастомной БЛ в этапах автоматизации */
  showAutomationStepLogic = 'showAutomationStepLogic',
  /** Реализация раздела "Справочники" */
  customReferences = 'customReferences',
  /** Функционал биллинга, который нужно запустить ровно с 01.03 */
  billingValidFrom1March = 'billingValidFrom1March',
  /** Скрытие лого, которое нужно для демо на с 29.02 */
  hideLogo = 'hideLogo',
  /** Опросник Testograf */
  testograf = 'testograf',
  /** Опросник Testograf для ГВ */
  testografCargoOwner = 'testografCargoOwner',
  /**  Ролевая модель сибур */
  siburAdditionalRolesModel = 'siburAdditionalRolesModel',
  /** Возможность забрать часть объема по подбору */
  divisibleReduction = 'divisibleReduction',
  /** Проверка условий для ограничения на содание подбора(Работа с заказами 1:M) */
  executionRequestsAndRunsOneToMultipleWorkflow = 'executionRequestsAndRunsOneToMultipleWorkflow',
  /** Отображение нового дизайна карточки рейса */
  runPreviewRedesign = 'runPreviewRedesign',
  /** Добавление модальности и типа транспортировки к воротам ЦО */
  digitalQueueGatesModalityCheck = 'digitalQueueGatesModalityCheck',
  /** Добавление внешних ЦО */
  externalDigitalQueue = 'externalDigitalQueue',
  /** Использование нового подхода для формирования конфига в таблице */
  newTableConfigForExecutionRequests = 'newTableConfigForExecutionRequests',
  /** Ограничить максимально возможное количество одновременно включенных столбцов в одной папке */
  orderMaxColumns = 'orderMaxColumns',
  /** Ценообразование подборов */
  executorsLookupsPricingModel = 'executorsLookupsPricingModel',
  /** Расширенная настройка допущенных исполнителей до этапа */
  potentialParticipantsForm = 'potentialParticipantsForm',
  /** Заменяем запрос получения списка участников при создание подбора */
  replaceUseRequestPotentialParticipants = 'replaceUseRequestPotentialParticipants',
  /** Добавление выбора модальности и типа перевозки для Самовывоза */
  shipmentModality = 'shipmentModality',
  /** Отображение документов в заявках по 1:М */
  multipleAgreementDocuments = 'multipleAgreementDocuments',
  /** Общий километраж для маршрута */
  manualDistance = 'manualDistance',
  /** Создание инстанса приложения с набором ограничений по лицензии */
  licenseSettingsForInstanceWithoutBilling = 'licenseSettingsForInstanceWithoutBilling',
  /** Создание и редактирование бронирований под Самовывоз из ЦО */
  digitalQueueShipment = 'digitalQueueShipment',
  /** Загрузка множественных документов в самовывозе */
  shipmentGeneralBookingDocuments = 'shipmentGeneralBookingDocuments',
  /** Возможность выкупить объем без торгов в редукционе */
  reductionBuyNow = 'reductionBuyNow',
  /** Комментарий в форме бронирования для Заказа и Самовывоза */
  commentInBookings = 'commentInBookings',
  /** Добавление управление партнерскими ресурсами */
  addPartnershipResourcesManagement = 'addPartnershipResourcesManagement',
  /** Выбор нескольких покупателей и указание типа ТС при создании самовывоза */
  shipmentMultipleCustomers = 'shipmentMultipleCustomers',
  /** Указание дефолтной маржи и автостарт редукциона */
  partnershipsDefaultMarginPercentAndReductionAutoPublish = 'partnershipsDefaultMarginPercentAndReductionAutoPublish',
  /** Обновление access-токена */
  refreshAccessToken = 'refreshAccessToken',
  /** Реализация ЦО 2.0. Новое расписание и сетка */
  digitalQueue2 = 'digitalQueue2',
  /**  Доступ к разделу Реестры */
  selfBillingRegistries = 'selfBillingRegistries',
  /** Миграция модуля Tenders из Vuex в Pinia */
  piniaMigrationStoreTenders = 'piniaMigrationStoreTenders',
  /** Отображение paywallMessage о наличии задолженности */
  billingDebtPaywallMessage = 'billingDebtPaywallMessage',
  /** Новая виртуализация по Y для таблицы с поддержкой строк разной высоты */
  newVirtualYScroll = 'newVirtualYScroll',
  /** Преселект матрицы распределения  */
  matrixPreselect = 'matrixPreselect',
  /** Поиск в ресурсах партнеров */
  addSearchByPartnershipResources = 'addSearchByPartnershipResources',
  /** Фильтры в ресурсах партнеров */
  addFilterByPartnershipResources = 'addFilterByPartnershipResources',
  /** Назначение логистов на заказы клиента */
  executorResponsibleEmployee = 'executorResponsibleEmployee',
  /** Рекламный баннер для кнопки "Забрать сразу" */
  settingForBuyNow = 'settingForBuyNow',
  /** Новая ролевая модель для доступа к папкам заказов */
  equalizeOrdersStatesToFolders = 'equalizeOrdersStatesToFolders',
  /** Переименовать команду и событие AddUnreadObjectsByChangedExecutionRequest */
  addUnreadChangesMarkerInsteadOfUnreadObject = 'addUnreadChangesMarkerInsteadOfUnreadObject',
}

export enum ECompanyRole {
  executor = 'executor',
  customer = 'customer',
  shipper = 'shipper',
}

export const ADDRESS_SOURCES = {
  daData: 'da_data',
  db: 'db',
  user: 'user',
};

export const SUPPORT_EMAIL = 'support@tms.ru';

// TODO: при переносе в src/constants ломается tt(). Нужно разобраться с проблемой
export enum ELocale {
  ru = 'ru',
  en = 'en',
}

export enum EStatusType {
  default = 'default',
  error = 'error',
  processing = 'processing',
  success = 'success',
  warning = 'warning',
}

export enum ERunWorkflowPhase {
  waitingForExecution = 'waiting_for_execution',
  executionInProgress = 'execution_in_progress',
  executionFinished = 'execution_finished',
  executionCancelled = 'execution_cancelled',
}

export enum ETenantType {
  customer = 'customer',
  executor = 'executor',
  shipper = 'shipper',
}

export enum EAddressBound {
  country = 'country',
  region = 'region',
  area = 'area',
  city = 'city',
  settlement = 'settlement',
  street = 'street',
  block = 'block',
}

export enum ERouteProgress {
  allProgress = 'all_progress',
  selfProgress = 'self_progress',
}

export enum EAdmissionToLookupsParticipation {
  noContractsNeeded = 'no_contracts_needed',
  contractStrictlyRequired = 'contract_strictly_required',
  followingByLookupSettings = 'following_by_lookup_settings',
}

export enum ECreationLookupType {
  strategy = 'strategy',
  manually = 'manually',
}

export enum EAutomationStepsSpotOfferAttributes {
  accessLevel = 'accessLevel',
  currency = 'currency',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  accessRules = 'accessRules',
  duration = 'duration',
  price = 'price',
  priceType = 'priceType',
}

export enum EAutomationStepsTargetOfferAttributes {
  currency = 'currency',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  duration = 'duration',
  price = 'price',
  partnershipId = 'partnershipId',
}

export enum EAutomationStepsQuotaAttributes {
  currency = 'currency',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  duration = 'duration',
  guaranteeTagName = 'guaranteeTagName',
  quotaAccountingType = 'quotaAccountingType',
  price = 'price',
  pricingModel = 'pricingModel',
}

export enum EAutomationStepsReductionAttributes {
  accessLevel = 'accessLevel',
  currency = 'currency',
  offeringType = 'offeringType',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  accessRules = 'accessRules',
  duration = 'duration',
  percentStep = 'percentStep',
  exactStep = 'exactStep',
  expirationStartsAfter = 'expirationStartsAfter',
  manualBidSelection = 'manualBidSelection',
  manualBidSelectionDuration = 'manualBidSelectionDuration',
  startingPrice = 'startingPrice',
  stepType = 'stepType',
  automaticProlongation = 'automaticProlongation',
  buyNowEnabled = 'buyNowEnabled',
  buyNowPercent = 'buyNowPercent',
}

export enum EAutomationStepsFreeOfferingAttributes {
  accessLevel = 'accessLevel',
  currency = 'currency',
  maxPrice = 'maxPrice',
  minPrice = 'minPrice',
  offerSelectionDuration = 'offerSelectionDuration',
  offeringDuration = 'offeringDuration',
  offeringType = 'offeringType',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  accessRules = 'accessRules',
  automaticProlongation = 'automaticProlongation',
}

export enum ELookupUsageContext {
  order = 'order',
  agreement = 'agreement',
}

export const MORE_INFO_ABOUT_PLANS_URL = 'https://confluence.trucker.group/manual/9-tarify-i-usloviya-2535822689.html';

/** Ссылка на договор офферты для подписки на кабинет ГВ (грузовладельца) */
export const CARGO_OWNER_PUBLIC_OFFER_LINK = '/docs/default/contract-offer-customer.pdf';
/**  Ссылка на договор офферты для подписки на кабинет ГП (грузоперевозчик) или кабинет ГО/ГП (грузоотправитель/грузополучатель) */
export const CARRIER_PUBLIC_OFFER_LINK = '/docs/default/contract-offer.pdf';

/**
 * Ссылка на инструкцию по кнопке (опции) "Забрать сразу" для ГП
 * */
export const MORE_INFO_ABOUT_BUY_NOW_FOR_CARRIER = 'https://confluence.trucker.group/manual/6-2-1-1-knopka-zabrat-srazu-2565683747.html';

export const COUNT_OF_BILLING_TRIAL_PERIOD_DAYS = 14;

export enum EDocumentTypeForRuns {
  deliveryNote = 'delivery_note',
  expeditorAgentReceipt = 'expeditor_agent_receipt',
  materialLoadList = 'material_load_list',
  other = 'other',
  powerOfAttorney = 'power_of_attorney',
  general = 'general',
}

export enum EDriverFormField {
  fullName = 'fullName',
  birthday = 'birthday',
  birthplace = 'birthplace',
  passportNumber = 'passportNumber',
  passportIssuedOn = 'passportIssuedOn',
  passportIssuedBy = 'passportIssuedBy',
  registrationAddress = 'registrationAddress',
  phone = 'phone',
  inn = 'inn',
}

export enum EPowerOfAttorneyFormField {
  powerOfAttorneyNumber = 'powerOfAttorneyNumber',
  powerOfAttorneyIssuedOn = 'powerOfAttorneyIssuedOn',
  powerOfAttorneyInfo = 'powerOfAttorneyInfo',
  bookingDocuments = 'bookingDocuments',
}

export enum ETruckFormField {
  truckLicensePlate = 'truckLicensePlate',
  truckBrand = 'truckBrand',
  truckOwnership = 'truckOwnership',
  ownershipDocument = 'ownershipDocument',
  executorName = 'executorName',
  executorInn = 'executorInn',
  contractNumber = 'contractNumber',
  contractSignedOnDate = 'contractSignedOnDate',
  truckForwarderExecutor = 'truckForwarderExecutor',
  truckForwarderExecutorInn = 'truckForwarderExecutorInn',
  truckForwarderContract = 'truckForwarderContract',
  truckForwarderContractIssuedOn = 'truckForwarderContractIssuedOn',
}

export enum ETrailerFormField {
  trailerLicensePlate = 'trailerLicensePlate',
  trailerBrand = 'trailerBrand',
  trailerOwnership = 'trailerOwnership',
  ownershipDocument = 'ownershipDocument',
  executorName = 'executorName',
  executorInn = 'executorInn',
  contractNumber = 'contractNumber',
  contractSignedOnDate = 'contractSignedOnDate',
  trailerForwarderExecutor = 'trailerForwarderExecutor',
  trailerForwarderExecutorInn = 'trailerForwarderExecutorInn',
  trailerForwarderContract = 'trailerForwarderContract',
  trailerForwarderContractIssuedOn = 'trailerForwarderContractIssuedOn',
}
