import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { TDigitalQueueGates } from '@/contexts/contractorsContext/domain/types';
import { TTableColumn } from '@/types';
import {
  EDigitalQueuePageTab,
  EDigitalQueuePageType,
  EDigitalQueuePageView,
} from '@/contexts/digitalQueuesContext/domain/constants';
import { TDigitalQueuePermissions } from '@/contexts/digitalQueuesContext/domain/types';

import type {
  Nullable,
  TDigitalQueueSettingsStore,
  TGateSettings,
  TColumnsSettings,
} from './types';

export const useDigitalQueueSettingsStore = defineStore(
  'digitalQueueSettings',
  (): TDigitalQueueSettingsStore => {
    const weekNumber = ref<string>('');
    const startDateOfCurrentWeek = ref<string>(''); // Дата начала выбранной недели
    const currentDigitalQueueId = ref<string>('');
    const openedGates = ref<TGateSettings[]>([]);
    const currentGateId = ref<string>('');
    const currentGates = ref<TDigitalQueueGates[]>([]);
    // Удалить после переезда в новый контекст digitalQueuesContext
    const pageType = ref<EDigitalQueuePageType>(EDigitalQueuePageType.booking);
    // Удалить после переезда в новый контекст digitalQueuesContext
    const viewMode = ref<EDigitalQueuePageView>(EDigitalQueuePageView.timeline);
    const permissions = ref<TDigitalQueuePermissions | null>(null);
    const columnsSettings = ref<TColumnsSettings[]>([]);
    // Табы "Настройка таймслотов" и "Редактирование шаблона"
    const currentPageTab = ref<EDigitalQueuePageTab>(EDigitalQueuePageTab.timeSlots);

    const $reset = () => {
      currentDigitalQueueId.value = '';
      openedGates.value = [];
      currentGates.value = [];
      permissions.value = null;
      currentGateId.value = '';
    };

    const setDigitalQueueId = (id: Nullable<string>) => {
      currentDigitalQueueId.value = id || '';
    };

    const setWeekNumber = (newWeekNumber: string) => {
      weekNumber.value = newWeekNumber;
    };

    const setStartDateOfCurrentWeek = (date: string) => {
      startDateOfCurrentWeek.value = date;
    };

    const setOpenedGates = (gates: TGateSettings[]) => {
      openedGates.value = gates;
    };

    const setCurrentGates = (gates: TDigitalQueueGates[]) => {
      currentGates.value = gates;
    };

    const setCurrentGateId = (id: string) => {
      currentGateId.value = id;
    };

    const setPageType = (newPageType: EDigitalQueuePageType) => {
      pageType.value = newPageType;
    };

    const setViewMode = (newViewMode: EDigitalQueuePageView) => {
      viewMode.value = newViewMode;
    };

    const setViewPageType = (newPageTab: EDigitalQueuePageTab) => {
      currentPageTab.value = newPageTab;
    };

    const setGateSlotDuration = (payload: TGateSettings) => {
      openedGates.value
        .find((gate: TGateSettings) => payload.id === gate.id)!.slotDuration = payload.slotDuration;
    };

    const setPermissions = (payload: TDigitalQueuePermissions | null) => {
      permissions.value = payload;
    };

    const setColumnsSettings = (digitalQueueId: string, payload: TTableColumn[]) => {
      const foundDigitalQueue = columnsSettings.value.find((item: TColumnsSettings) => item.id === digitalQueueId);

      if (foundDigitalQueue) {
        const mergeMap = new Map(foundDigitalQueue.columnsSettings.map((item: TTableColumn) => [item.slug, item]));

        payload.forEach((item: TTableColumn) => {
          mergeMap.set(item.slug, item);
        });

        const newColumnSettings = Array.from(mergeMap.values());
        foundDigitalQueue.columnsSettings = newColumnSettings;
      } else {
        columnsSettings.value.push({
          id: digitalQueueId,
          columnsSettings: payload,
        });
      }
    };

    return {
      weekNumber,
      currentDigitalQueueId,
      currentGateId,
      openedGates,
      currentGates,
      pageType,
      viewMode,
      permissions,
      columnsSettings,
      currentPageTab,
      startDateOfCurrentWeek,

      setWeekNumber,
      setDigitalQueueId,
      setOpenedGates,
      setCurrentGates,
      setCurrentGateId,
      setPageType,
      setViewMode,
      setGateSlotDuration,
      setPermissions,
      setColumnsSettings,
      setViewPageType,
      setStartDateOfCurrentWeek,

      $reset,
    };
  },
  { persist: true },
);
